import React, {useEffect, useState} from 'react';
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase, FiCalendar,
  FiChevronRight, FiEye,
  FiFile,
  FiHome,
  FiPieChart,
  FiUserPlus
} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {getFinancialReports, getFinancialStatistics, getOmanCampaignStatistics} from "../services/customSdpApiService";
import DatePicker from "react-datepicker";


const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function OmanTelRevenueModal(){
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get('/data.json')
      .then((response) => {
        setData(response.data.OMANTELRevenue);
      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
            <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
            <td className="border border-gray-300 px-4 py-2">{row.share}</td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue.toLocaleString()}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare.toLocaleString()}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function RevenueModal({opId, dataYear}){

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalShare, setTotalShare] = useState(0);

  const fetchRevenue = async ()=>{
    const submitData = {
      opId: opId,
      year: dataYear
    }
    await getFinancialStatistics(submitData)
      .then((res)=>{
        setData(res.revenueByMonth?.sort((a,b)=> a.month - b.month ))
        setTotalRevenue(res.totalRevenue)
        setTotalShare(res.totalSPShare)
      })
      .catch((err)=>console.log(err));
  }

  useEffect(() => {
    fetchRevenue();
  }, [dataYear]);

  if (!data) {
    return <div>Loading...</div>;
  }


  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">
              {months[parseInt(row.month) - 1] || ""}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {row.revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {row.spShare?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function TotalMarketRevenueTable({dataYear, ksaTotal, omanTotal}){
  return (
    <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
        <tr>
          <th colSpan="4"
              className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
            Total Market revenue {dataYear}
          </th>
        </tr>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Country</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className="border border-gray-300 px-4 py-2">KSA</td>
          <td className="border border-gray-300 px-4 py-2">
            {ksaTotal?.TotalRevenue?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </td>
          <td className="border border-gray-300 px-4 py-2">
            {ksaTotal?.TotalSPShare?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </td>
          <td className="border border-gray-300 px-4 py-2">
            {months[parseInt(ksaTotal?.lastMonth) - 1] || ""}
          </td>
        </tr>
        <tr>
          <td className="border border-gray-300 px-4 py-2">OMAN</td>
          <td className="border border-gray-300 px-4 py-2">
            {omanTotal?.TotalRevenue?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </td>
          <td className="border border-gray-300 px-4 py-2">
            {omanTotal?.TotalSPShare?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </td>
          <td className="border border-gray-300 px-4 py-2">
            {months[parseInt(omanTotal?.lastMonth) - 1] || ""}</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

function TotalMarketRevenueKSATable({dataYear, openRevenueModal, stcFinancial, mobilyFinancial, zainFinancial, zainFgcFinancial}){

  const data = [
    {id: 4, title: 'STC', data: stcFinancial},
    {id: 2, title: 'MOBILY', data: mobilyFinancial},
    {id: 3, title: 'ZAIN', data: zainFinancial},
    {id: 9, title: 'ZAIN-FGC', data: zainFgcFinancial},
  ]

  return (
    <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
        <tr>
          <th colSpan="5"
              className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
            Market revenue KSA {dataYear}
          </th>
        </tr>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Action</th>
        </tr>
        </thead>
        <tbody>
        {
          data.map((row, index) => (
            <tr key={row.id} >
              <td
                onClick={() => openRevenueModal(row.id)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                {row.title}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {row.data?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {row.data?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">{months[parseInt(row.data?.MaxMonth) - 1] || ""}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(row.id)}/>
                </button>
              </td>
            </tr>
          ))
        }
        <tr>
          <td
            onClick={() => openRevenueModal(9)}
            className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
            RBM
          </td>
          <td className="border border-gray-300 px-4 py-2">
            0
          </td>
          <td className="border border-gray-300 px-4 py-2">
            0
          </td>
          <td className="border border-gray-300 px-4 py-2">
            -
          </td>
          <td className="border border-gray-300 px-4 py-2">
            <button disabled>
              <FiEye size={18} className="hover:text-azure"/>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

function TotalMarketRevenueOmanTable({dataYear, openRevenueModal, omanTelFinancial}){
  const data = [
    {id: 5, title: 'OmanTel', data: omanTelFinancial},
    {id: 8, title: 'Ooredoo', data: {}},
  ]

  return (
    <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
        <tr>
          <th colSpan="5"
              className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
            Market revenue OMAN {dataYear}
          </th>
        </tr>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Action</th>
        </tr>
        </thead>
        <tbody>
        {
          data.map((row, index) => (
            <tr key={row.id} >
              <td
                onClick={() => openRevenueModal(row.id)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                {row.title}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {row.data?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {row.data?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">{months[parseInt(row.data?.MaxMonth) - 1] || ""}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(row.id)}/>
                </button>
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  )
}

function CampainStatisticsTable({title, data}) {
  return (
    <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
        <tr>
          <th colSpan="4" className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
            {title}
          </th>
        </tr>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Spent SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue estimated SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue earned SAR</th>
        </tr>
        </thead>
        <tbody>
          {
            data.map((row) => (
              <tr key={row.id} >
                <td className="border border-gray-300 px-4 py-2">
                  {row.title}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {row.data?.Spent?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || 0}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {row.data?.RevenueEstimated?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || 0}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {row.data?.ROI?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }) || 0}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

function Dashboard() {

  const {t, i18n} = useTranslation();

  const [dataYear, setDataYear] = useState(2024);

  const [zainFinancial, setZainFinancial] = useState({});
  const [stcFinancial, setStcFinancial] = useState({});
  const [mobilyFinancial, setMobilyFinancial] = useState({});
  const [zainFgcFinancial, setZainFgcFinancial] = useState({});
  const [omanTelFinancial, setOmanTelFinancial] = useState({});
  const [ksaTotal, setKsaTotal] = useState({TotalRevenue: 0, TotalSPShare: 0, lastMonth: 1});
  const [omanTotal, setOmanTotal] = useState({TotalRevenue: 0, TotalSPShare: 0, lastMonth: 1});

  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);
  const [revenueModalData, setRevenueModalData] = useState(null);

  const [campaignStatisticsStc, setCampaignStatisticsStc] = useState({});
  const [campaignStatisticsMobily, setCampaignStatisticsMobily] = useState({});
  const [campaignStatisticsZain, setCampaignStatisticsZain] = useState({});
  const [campaignStatisticsRbm, setCampaignStatisticsRbm] = useState({});

  const [campaignStatisticsOmanTel, setCampaignStatisticsOmanTel] = useState({});
  const [campaignStatisticsOreddo, setCampaignStatisticsOreddo] = useState({});

  const KsaCampaignStatistics = [
    {id: 1, title: 'STC', data: campaignStatisticsStc},
    {id: 2, title: 'Mobily', data: campaignStatisticsMobily},
    {id: 3, title: 'Zain', data: campaignStatisticsZain},
    {id: 4, title: 'RBM', data: campaignStatisticsRbm},
  ];

  const OmanCampaignStatistics = [
    {id: 1, title: 'OmanTel', data: campaignStatisticsOmanTel},
    {id: 2, title: 'Ooredoo', data: campaignStatisticsOreddo},
  ];

  const openRevenueModal = (opId) => {
    setIsRevenueModalOpen(true);
    setRevenueModalData(opId);
  }

  const closeRevenueModal = () => {
    setRevenueModalData(null);
    setIsRevenueModalOpen(false);
  }

  const fetchCampaignStatistics = async (id, setFunction) => {
    try {
      const res = await getOmanCampaignStatistics(id, dataYear);
      setFunction(res[0]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchAllStatistics = async () => {
      await Promise.all([
        fetchCampaignStatistics(4, setCampaignStatisticsStc),
        fetchCampaignStatistics(2, setCampaignStatisticsMobily),
        fetchCampaignStatistics(3, setCampaignStatisticsZain),
        fetchCampaignStatistics(7, setCampaignStatisticsRbm),
        fetchCampaignStatistics(5, setCampaignStatisticsOmanTel),
        fetchCampaignStatistics(8, setCampaignStatisticsOreddo),
      ]);
    };

    fetchAllStatistics();
  }, [dataYear]);

  const fetchFinancialReports = async (requests, setTotal) => {
    try {
      const results = await Promise.allSettled(
        requests.map(async ({ opId, setter }) => {
          const response = await getFinancialReports({ opId, year: dataYear });
          setter(response);
          return response;
        })
      );

      let totalRevenue = 0;
      let totalSPShare = 0;
      let maxMonth = 0;

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value;
          totalRevenue += data.TotalRevenue || 0;
          totalSPShare += data.TotalSPShare || 0;
          maxMonth = Math.max(maxMonth, data.MaxMonth || 0);
        } else {
          console.error(`Error fetching financial report for opId ${requests[index].opId}:`, result.reason);
        }
      });

      setTotal({
        TotalRevenue: totalRevenue,
        TotalSPShare: totalSPShare,
        lastMonth: maxMonth,
      });
    } catch (error) {
      console.error("Unexpected error fetching financial reports:", error);
    }
  };

  useEffect(() => {
    const ksaRequests = [
      { opId: 3, setter: setZainFinancial },
      { opId: 4, setter: setStcFinancial },
      { opId: 2, setter: setMobilyFinancial },
      { opId: 9, setter: setZainFgcFinancial },
    ];

    const omanRequests = [
      { opId: 5, setter: setOmanTelFinancial },
    ];

    fetchFinancialReports(ksaRequests, setKsaTotal);
    fetchFinancialReports(omanRequests, setOmanTotal);
  }, [dataYear]);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className="flex items-center justify-end">
        <div className="relative border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-80 col-span-2">
          <FiCalendar className="text-gray-500 absolute top-1/2 -translate-y-1/2 left-3 z-20" size={14} />
          <DatePicker
            selected={new Date(dataYear, 0, 1)}
            onChange={(date) => setDataYear(date.getFullYear())}
            dateFormat="yyyy"
            showYearPicker
            placeholderText="Select Year"
            isClearable={false}
            wrapperClassName="w-80"
            className="w-80 pl-8"
          />
        </div>
      </div>

      <TotalMarketRevenueTable
        dataYear={dataYear}
        ksaTotal={ksaTotal}
        omanTotal={omanTotal}
      />

      <div className="grid grid-cols-2 gap-5">

        <TotalMarketRevenueKSATable
          dataYear={dataYear}
          openRevenueModal={openRevenueModal}
          stcFinancial={stcFinancial}
          mobilyFinancial={mobilyFinancial}
          zainFinancial={zainFinancial}
          zainFgcFinancial={zainFgcFinancial}
        />

        <CampainStatisticsTable
          title={`Campaign statistics KSA ${dataYear}`}
          data={KsaCampaignStatistics}
        />

        <TotalMarketRevenueOmanTable
          dataYear={dataYear}
          openRevenueModal={openRevenueModal}
          omanTelFinancial={omanTelFinancial}
        />

        <CampainStatisticsTable
          title={`Campaign statistics OMAN ${dataYear}`}
          data={OmanCampaignStatistics}
        />

      </div>

      <Modal isOpen={isRevenueModalOpen} close={closeRevenueModal} title={`Revenues - ${dataYear}`} >
        <RevenueModal opId={revenueModalData} dataYear={dataYear} />
      </Modal>
    </div>
  );
}

export default Dashboard;
